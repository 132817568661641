import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { SharedModule } from './shared/shared.module';
import { AdvancedSortableDirective } from './core/services/tables/advanced-sortable.directive';
import { SpinnerHttpInterceptor } from './core/intercepters/spinner-http-intercepter';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { DatePipe } from '@angular/common';
import { AssetRegisterComponent } from './pages/reports/asset-register/asset-register.component';
import { CreditorAgeAnalysisComponent } from './pages/reports/creditor-age-analysis/creditor-age-analysis.component';
import { CreditorsLedgerComponent } from './pages/reports/creditors-ledger/creditors-ledger.component';
import { DeliveriesByStockComponent } from './pages/reports/deliveries-by-stock/deliveries-by-stock.component';
import { DeliveriesListingComponent } from './pages/reports/deliveries-listing/deliveries-listing.component';
import { IventoryLedgerComponent } from './pages/reports/iventory-ledger/iventory-ledger.component';
import { LedgerTransactionsComponent } from './pages/reports/ledger-transactions/ledger-transactions.component';
import { OrdersListingComponent } from './pages/reports/orders-listing/orders-listing.component';
//import { ReconciledDeliveriesByStockComponent } from './pages/reports/reconciled-deliveries-by-stock/reconciled-deliveries-by-stock.component';
import { RequisitionsAwaitingApprovalComponent } from './pages/reports/requisitions-awaiting-approval/requisitions-awaiting-approval.component';
import { RequisitionsListingComponent } from './pages/reports/requisitions-listing/requisitions-listing.component';
import { UnreconciledDeliveriesComponent } from './pages/reports/unreconciled-deliveries/unreconciled-deliveries.component';
import { InventoryComponent } from './pages/reports/inventory/inventory.component';
import { DebtorAgeAnalysisComponent } from './pages/reports/debtor-age-analysis/debtor-age-analysis.component';
import { SalesByCustomerComponent } from './pages/reports/sales-by-customer/sales-by-customer.component';
import { SalesByItemComponent } from './pages/reports/sales-by-item/sales-by-item.component';
import { SalesJournalComponent } from './pages/reports/sales-journal/sales-journal.component';
import { VatComponent } from './pages/reports/vat/vat.component';
import { VatClaimComponent } from './pages/reports/vat-claim/vat-claim.component';
import { MaterialCostByProductComponent } from './pages/reports/material-cost-by-product/material-cost-by-product.component';
import { CommonModule } from 'node_modules/@angular/common/common';
import { NgbModule } from 'node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap';
import { DbService } from './db.service';
//import { NgxDropzoneModule } from 'ngx-dropzone';
// import { FarmersComponent } from './pages/reports/farmers/farmers.component';
// import { FarmsComponent } from './pages/reports/farms/farms.component';
// import { FarmVisitsComponent } from './pages/reports/farm-visits/farm-visits.component';
// import { FarmVisitSchedulesComponent } from './pages/reports/farm-visit-schedules/farm-visit-schedules.component';
// import { LoanFacilitiesComponent } from './pages/reports/loan-facilities/loan-facilities.component';
// import { DealsComponent } from './pages/reports/deals/deals.component';
// import { ContractsComponent } from './pages/reports/contracts/contracts.component';
// import { CompaniesComponent } from './pages/reports/companies/companies.component';
// import { FarmersByCompanyComponent } from './pages/reports/farmers-by-company/farmers-by-company.component';
// import { FarmersByTypeComponent } from './pages/reports/farmers-by-type/farmers-by-type.component';
// import { DealsByTypeComponent } from './pages/reports/deals-by-type/deals-by-type.component';
// import { DealsByFarmerComponent } from './pages/reports/deals-by-farmer/deals-by-farmer.component';
// import { CompaniesByTypeComponent } from './pages/reports/companies-by-type/companies-by-type.component';
// import { ExtensionOfficersComponent } from './pages/reports/extension-officers/extension-officers.component';
// import { FarmVisitsByExtensionOfficerComponent } from './pages/reports/farm-visits-by-extension-officer/farm-visits-by-extension-officer.component';
// import { FarmVisitSchedulesByExtensionOfficerComponent } from './pages/reports/farm-visit-schedules-by-extension-officer/farm-visit-schedules-by-extension-officer.component';
// import { FarmersByExtensionOfficerComponent } from './pages/reports/farmers-by-extension-officer/farmers-by-extension-officer.component';
// import { LoanFacilitiesByFarmerComponent } from './pages/reports/loan-facilities-by-farmer/loan-facilities-by-farmer.component';
// import { FarmVisitsByFarmerComponent } from './pages/reports/farm-visits-by-farmer/farm-visits-by-farmer.component';
// import { ContractsByFarmerComponent } from './pages/reports/contracts-by-farmer/contracts-by-farmer.component';
// import { FarmVisitSchedulesByFarmerComponent } from './pages/reports/farm-visit-schedules-by-farmer/farm-visit-schedules-by-farmer.component';
// import { ContractsByCompanyComponent } from './pages/reports/contracts-by-company/contracts-by-company.component';
//import { ExtensionOfficersByCompanyComponent } from './pages/reports/extension-officers-by-company/extension-officers-by-company.component';
//import { PurchaseInvoicesComponent } from './pages/procurement/purchase-invoices/purchase-invoices.component';
// //import { ZimbabweTobaccoAssociationCreditorCollectionsComponent } from './pages/reports/zimbabwe-tobacco-association-creditor-collections/zimbabwe-tobacco-association-creditor-collections.component';
import { PurchaseInvoicesFloorsComponent } from './pages/reports/purchase-invoices/purchase-invoices-floors.component';
import { DealsByFarmerComponent } from './pages/farming-reports/deals-by-farmer/deals-by-farmer.component';
import { DealsByTypeComponent } from './pages/farming-reports/deals-by-type/deals-by-type.component';


//import { FarmVisitsByExtensionOfficerComponent } from './pages/farming-reports/farm-visits-by-extension-officer/farm-visits-by-extension-officer.component';
//import { FarmVisitsByFarmerComponent } from './pages/farming-reports/farm-visits-by-farmer/farm-visits-by-farmer.component';
import { FarmersByCompanyComponent } from './pages/farming-reports/farmers-by-company/farmers-by-company.component';
//import { FarmersByExtensionOfficerComponent } from './pages/farming-reports/farmers-by-extension-officer/farmers-by-extension-officer.component';
import { FarmersByTypeComponent } from './pages/farming-reports/farmers-by-type/farmers-by-type.component';
import { LoanFacilitiesByFarmerComponent } from './pages/farming-reports/loan-facilities-by-farmer/loan-facilities-by-farmer.component';
import { MinistryOfAgricultureCreditorCollectionsComponent } from './pages/farming-reports/ministry-of-agriculture-creditor-collections/ministry-of-agriculture-creditor-collections.component';
import { RbzDailySoldBalesComponent } from './pages/farming-reports/rbz-daily-sold-bales/rbz-daily-sold-bales.component';
import { SalesFloorChargesComponent } from './pages/farming-reports/sales-floor-charges/sales-floor-charges.component';
import { SalesSheetsByFarmerComponent } from './pages/farming-reports/sales-sheets-by-farmer/sales-sheets-by-farmer.component';
import { SalesSheetsComponent } from './pages/farming-reports/sales-sheets/sales-sheets.component';
import { ZimbabweTobaccoAssociationCreditorCollectionsComponent } from './pages/farming-reports/zimbabwe-tobacco-association-creditor-collections/zimbabwe-tobacco-association-creditor-collections.component';
import { ReportCompaniesComponent } from './pages/farming-reports/companies/report-companies.component';
import { ReportContractsComponent } from './pages/farming-reports/contracts/report-contracts.component';
import { DealsComponent } from './pages/farming-reports/deals/deals.component';
//import { ExtensionOfficersComponent } from './pages/farming/extensionOfficers/extensionOfficers.component';
import { FarmersComponent } from './pages/farming-reports/farmers/farmers.component';
import { FarmsComponent } from './pages/farming-reports/farms/farms.component';
import { FarmVisitsComponent } from './pages/farming-reports/farm-visits/farm-visits.component';
//  import { FarmVisitSchedulesComponent } from './pages/farming/farmVisitSchedules/farmVisitSchedules.component';
import { LoanFacilitiesComponent } from './pages/farming-reports/loan-facilities/loan-facilities.component';
import { ReportContractsByCompanyComponent } from './pages/farming-reports/contracts-by-company/report-contracts-by-company.component';
import { ReportContractsByFarmerComponent } from './pages/farming-reports/contracts-by-farmer/report-contracts-by-farmer.component';
import { ReportAfforestationLevyCreditorCollectionsComponent } from './pages/farming-reports/afforestation-levy-creditor-collections/report-afforestation-levy-creditor-collections.component';
import { ReportCompaniesByTypeComponent } from './pages/farming-reports/companies-by-type/report-companies-by-type.component';
import { FarmVisitSchedulesByExtensionOfficerComponent } from './pages/farming-reports/farm-visit-schedules-by-extension-officer/farm-visit-schedules-by-extension-officer.component';
import { FarmVisitSchedulesByFarmerComponent } from './pages/farming-reports/farm-visit-schedules-by-farmer/farm-visit-schedules-by-farmer.component';
import { FarmVisitsByExtensionOfficerComponent } from './pages/farming-reports/farm-visits-by-extension-officer/farm-visits-by-extension-officer.component';
import { FarmVisitsByFarmerComponent } from './pages/farming-reports/farm-visits-by-farmer/farm-visits-by-farmer.component';
import { FarmersByExtensionOfficerComponent } from './pages/farming-reports/farmers-by-extension-officer/farmers-by-extension-officer.component';
import { ExtensionOfficersComponent } from './pages/farming-reports/extension-officers/extension-officers.component';
import { FarmVisitSchedulesComponent } from './pages/farming-reports/farm-visit-schedules/farm-visit-schedules.component';
import { ExtensionOfficersByCompanyComponent } from './pages/farming-reports/extension-officers-by-company/extension-officers-by-company.component';
import { CohortsBySchoolComponent } from './pages/school-reports/cohorts-by-school/cohorts-by-school.component';
import { PayslipsByEmployeeComponent } from './pages/finance-reports/payslips-by-employee/payslips-by-employee.component';
import { StudentAttendancesByStudentComponent } from './pages/school-reports/student-attendances-by-student/student-attendances-by-student.component';
import { StudentReportsByStudentComponent } from './pages/school-reports/student-reports-by-student/student-reports-by-student.component';
import { StudentsByCohortComponent } from './pages/school-reports/students-by-cohort/students-by-cohort.component';
import { EmployeesBySchoolComponent } from './pages/school-reports/employees-by-school/employees-by-school.component';
import { StudentAttendancesByClassByDateComponent } from './pages/school-reports/student-attendances-by-class-by-date/student-attendances-by-class-by-date.component';
import { GvtAfforestationLevyCreditorCollectionsComponent } from './pages/government-reports/afforestation-levy-creditor-collections/gvt-afforestation-levy-creditor-collections.component';
import { GvtMinistryOfAgricultureCreditorCollectionsComponent } from './pages/government-reports/ministry-of-agriculture-creditor-collections/gvt-ministry-of-agriculture-creditor-collections.component';
import { GvtRbzDailySoldBalesComponent } from './pages/government-reports/rbz-daily-sold-bales/gvt-rbz-daily-sold-bales.component';
import { GvtZimbabweTobaccoAssociationCreditorCollectionsComponent } from './pages/government-reports/zimbabwe-tobacco-association-creditor-collections/gvt-zimbabwe-tobacco-association-creditor-collections.component';
import { SupplierFarmersComponent } from './pages/supplier-reports/farmers/supplier-farmers.component';
import { BankFarmersComponent } from './pages/bank-reports/farmers/bank-farmers.component';
import { BuyerFarmersComponent } from './pages/buyer-reports/farmers/buyer-farmers.component';
import { BankContractsByCompanyComponent } from './pages/bank-reports/contracts-by-company/bank-contracts-by-company.component';
import { BankContractsByFarmerComponent } from './pages/bank-reports/contracts-by-farmer/bank-contracts-by-farmer.component';
import { BuyerFarmersByCompanyComponent } from './pages/buyer-reports/farmers-by-company/buyer-farmers-by-company.component';
import { ContractorDealsComponent } from './pages/contractor-reports/contractor-deals/contractor-deals.component';
import { ContractorContractsByCompanyComponent } from './pages/contractor-reports/contracts-by-company/contractor-contracts-by-company.component';
import { ContractorDealsByFarmerComponent } from './pages/contractor-reports/deals-by-farmer/contractor-deals-by-farmer.component';
import { ContractorDealsByTypeComponent } from './pages/contractor-reports/deals-by-type/contractor-deals-by-type.component';
import { ContractorExtensionOfficersByCompanyComponent } from './pages/contractor-reports/extension-officers-by-company/contractor-extension-officers-by-company.component';
import { ContractorLoanFacilitiesByFarmerComponent } from './pages/contractor-reports/loan-facilities-by-farmer/contractor-loan-facilities-by-farmer.component';
import { ContractorLoanFacilitiesComponent } from './pages/contractor-reports/loan-facilities/contractor-loan-facilities.component';
import { ContractorSalesFloorChargesComponent } from './pages/contractor-reports/sales-floor-charges/contractor-sales-floor-charges.component';
import { ContractorSalesSheetsByFarmerComponent } from './pages/contractor-reports/sales-sheets-by-farmer/contractor-sales-sheets-by-farmer.component';
import { ContractorSalesSheetsComponent } from './pages/contractor-reports/sales-sheets/contractor-sales-sheets.component';
import { InsuranceFarmVisitsByFarmerComponent } from './pages/insurance-reports/farm-visits-by-farmer/insurance-farm-visits-by-farmer.component';
import { InsuranceFarmVisitsComponent } from './pages/insurance-reports/farm-visits/insurance-farm-visits.component';
import { TimbFarmersComponent } from './pages/timb-reports/farmers/timb-farmers.component';
import { TransporterFarmersComponent } from './pages/transporter-reports/farmers/transporter-farmers.component';
import { ExtoFarmersByExtensionOfficerComponent } from './pages/extensionofficer-reports/farmers-by-extension-officer/exto-farmers-by-extension-officer.component';
import { ExtensionOfficerDetailsComponent } from './pages/farming/extensionOfficerDetails/extensionOfficerDetails.component';
import { StudentAttendancesByStudentByTermComponent } from './pages/school-reports/student-attendances-by-student-by-term/student-attendances-by-student-by-term.component';
import { StudentReportByStudentByTermComponent } from './pages/school-reports/student-report-by-student-by-term/student-report-by-student-by-term.component';
import { OutstandingInvoicesByCohortComponent } from './pages/finance-reports/outstanding-invoices-by-cohort/outstanding-invoices-by-cohort.component';
import { OutstandingInvoicesByStreamComponent } from './pages/finance-reports/outstanding-invoices-by-stream/outstanding-invoices-by-stream.component';
import { OutstandingInvoicesByStudentComponent } from './pages/finance-reports/outstanding-invoices-by-student/outstanding-invoices-by-student.component';
import { PassRateByCohortComponent } from './pages/school-reports/pass-rate-by-cohort/pass-rate-by-cohort.component';
import { PassRateByCohortsComponent } from './pages/school-reports/pass-rate-by-cohorts/pass-rate-by-cohorts.component';
import { PassRateByStreamComponent } from './pages/school-reports/pass-rate-by-stream/pass-rate-by-stream.component';
import { PassRateByStreamsComponent } from './pages/school-reports/pass-rate-by-streams/pass-rate-by-streams.component';
import { PassRateBySubjectComponent } from './pages/school-reports/pass-rate-by-subject/pass-rate-by-subject.component';
import { PassRateBySubjectsByStreamsComponent } from './pages/school-reports/pass-rate-by-subjects-by-streams/pass-rate-by-subjects-by-streams.component';
import { PassRateBySubjectsComponent } from './pages/school-reports/pass-rate-by-subjects/pass-rate-by-subjects.component';
import { TimeTableByClassComponent } from './pages/school-reports/time-table-by-class/time-table-by-class.component';
import { TimeTableByTeacherComponent } from './pages/school-reports/time-table-by-teacher/time-table-by-teacher.component';
import { TimeTablesByClassesComponent } from './pages/school-reports/time-tables-by-classes/time-tables-by-classes.component';
import { TimeTablesByTeachersComponent } from './pages/school-reports/time-tables-by-teachers/time-tables-by-teachers.component';
import { OutstandingInvoicesByOneStudentComponent } from './pages/school-student-reports/outstanding-invoices-by-one-student/outstanding-invoices-by-one-student.component';
import { StudentAttendancesByOneStudentComponent } from './pages/school-student-reports/student-attendances-by-one-student/student-attendances-by-one-student.component';
import { StudentAttendancesByOneStudentByTermComponent } from './pages/school-student-reports/student-attendances-by-one-student-by-term/student-attendances-by-one-student-by-term.component';
import { StudentReportByOneStudentByTermComponent } from './pages/school-student-reports/student-report-by-one-student-by-term/student-report-by-one-student-by-term.component';
import { StudentReportsByOneStudentComponent } from './pages/school-student-reports/student-reports-by-one-student/student-reports-by-one-student.component';
import { TimeTableByOneClassComponent } from './pages/school-student-reports/time-table-by-one-class/time-table-by-one-class.component';
import { OutstandingInvoicesByEachStudentComponent } from './pages/school-teacher-reports/outstanding-invoices-by-each-student/outstanding-invoices-by-each-student.component';
import { OutstandingInvoicesByOneCohortComponent } from './pages/school-teacher-reports/outstanding-invoices-by-one-cohort/outstanding-invoices-by-one-cohort.component';
import { PassRateByOneCohortComponent } from './pages/school-teacher-reports/pass-rate-by-one-cohort/pass-rate-by-one-cohort.component';
import { PassRateByOneSubjectComponent } from './pages/school-teacher-reports/pass-rate-by-one-subject/pass-rate-by-one-subject.component';
import { PayslipsByOneEmployeeComponent } from './pages/school-teacher-reports/payslips-by-one-employee/payslips-by-one-employee.component';
import { StudentAttendancesByEachStudentComponent } from './pages/school-teacher-reports/student-attendances-by-each-student/student-attendances-by-each-student.component';
import { StudentAttendancesByOneClassByDateComponent } from './pages/school-teacher-reports/student-attendances-by-one-class-by-date/student-attendances-by-one-class-by-date.component';
import { StudentReportByEachStudentByTermComponent } from './pages/school-teacher-reports/student-report-by-each-student-by-term/student-report-by-each-student-by-term.component';
import { StudentReportsByEachStudentComponent } from './pages/school-teacher-reports/student-reports-by-each-student/student-reports-by-each-student.component';
import { StudentsByOneCohortComponent } from './pages/school-teacher-reports/students-by-one-cohort/students-by-one-cohort.component';
import { TimeTableByOneTeacherComponent } from './pages/school-teacher-reports/time-table-by-one-teacher/time-table-by-one-teacher.component';
import { CohortsByTheSchoolComponent } from './pages/school-headmaster-reports/cohorts-by-the-school/cohorts-by-the-school.component';
import { EmployeesByTheSchoolComponent } from './pages/school-headmaster-reports/employees-by-the-school/employees-by-the-school.component';
import { OutstandingInvoicesByTheCohortComponent } from './pages/school-headmaster-reports/outstanding-invoices-by-the-cohort/outstanding-invoices-by-the-cohort.component';
import { PassRateByAllCohortsComponent } from './pages/school-headmaster-reports/pass-rate-by-all-cohorts/pass-rate-by-all-cohorts.component';
import { PassRateByAllStreamsComponent } from './pages/school-headmaster-reports/pass-rate-by-all-streams/pass-rate-by-all-streams.component';
import { PassRateByAllSubjectsByStreamsComponent } from './pages/school-headmaster-reports/pass-rate-by-all-subjects-by-streams/pass-rate-by-all-subjects-by-streams.component';
import { PassRateByAllSubjectsComponent } from './pages/school-headmaster-reports/pass-rate-by-all-subjects/pass-rate-by-all-subjects.component';
import { TimeTablesByAllClassesComponent } from './pages/school-headmaster-reports/time-tables-by-all-classes/time-tables-by-all-classes.component';
import { TimeTablesByAllTeachersComponent } from './pages/school-headmaster-reports/time-tables-by-all-teachers/time-tables-by-all-teachers.component';
import { PayslipsByTheEmployeeComponent } from './pages/school-headmaster-reports/payslips-by-the-employee/payslips-by-the-employee.component';
import { TimeTableByEachClassComponent } from './pages/school-teacher-reports/time-table-by-each-class/time-table-by-each-class.component';
import { PayslipsByPayrollComponent } from './pages/finance-reports/payslips-by-payroll/payslips-by-payroll.component';
import { StudentReportsByOneStudentMillenialComponent } from './pages/school-student-reports/student-reports-by-one-student-millenial/student-reports-by-one-student-millenial.component';
import { StudentReportsByOneStudentSakubvaComponent } from './pages/school-student-reports/student-reports-by-one-student-sakubva/student-reports-by-one-student-sakubva.component';
import { AllPayslipsByPayrollComponent } from './pages/finance-reports/all-payslips-by-payroll/all-payslips-by-payroll.component';
import { TimeTableByStudentComponent } from './pages/school-reports/time-table-by-student/time-table-by-student.component';
import { TimeTablesByStudentsComponent } from './pages/school-reports/time-tables-by-students/time-tables-by-students.component';
import { SalaryScheduleByPayrollComponent } from './pages/finance-reports/salary-schedule-by-payroll/salary-schedule-by-payroll.component';
// import { EmployeeAttendancesBySchoolByDate } from './pages/school-headmaster-reports/employee-attendances-by-school-by-date/employee-attendances-by-school-by-date.component';
// import { EmployeeAttendancesByEmployeeByTerm } from './pages/school-headmaster-reports/employee-attendances-by-employee-by-term/employeev-by-employee-by-term.component';
// import { EmployeeAttendancesByEmployee } from './pages/school-headmaster-reports/employee-attendances-by-school-by-date/employee-attendances-by-school-by-date.component';
import { EmployeeAttendancesByEmployeeByTermComponent } from './pages/school-headmaster-reports/employee-attendances-by-employee-by-term/employee-attendances-by-employee-by-term.component';
import { EmployeeAttendancesByEmployeeComponent } from './pages/school-headmaster-reports/employee-attendances-by-employee/employee-attendances-by-employee.component';
import { EmployeeAttendancesByTheSchoolByDateComponent } from './pages/school-headmaster-reports/employee-attendances-by-the-school-by-date/employee-attendances-by-the-school-by-date.component';
import { StudentAttendancesByTheClassByDateComponent } from './pages/school-headmaster-reports/student-attendances-by-the-class-by-date/student-attendances-by-the-class-by-date.component';
import { StudentAttendancesByTheStudentByTermComponent } from './pages/school-headmaster-reports/student-attendances-by-the-student-by-term/student-attendances-by-the-student-by-term.component';
import { StudentAttendancesByTheStudentComponent } from './pages/school-headmaster-reports/student-attendances-by-the-student/student-attendances-by-the-student.component';
import { StudentsByTheCohortComponent } from './pages/school-headmaster-reports/students-by-the-cohort/students-by-the-cohort.component';

//import { PayslipByPayrollBySupplierComponent } from './pages/payroll/payslip-by-payroll-by-supplier/payslip-by-payroll-by-supplier.component';

//import { SalesSheetsComponent } from './pages/reports/sales-sheets/sales-sheets.component';
//import { NgbModule } from 'node_modules1/@ng-bootstrap/ng-bootstrap';

if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

@NgModule({
  declarations: [
    AppComponent,
    AssetRegisterComponent,
    CreditorAgeAnalysisComponent,
    DebtorAgeAnalysisComponent,
    CreditorsLedgerComponent,
    DeliveriesByStockComponent,
    DeliveriesListingComponent,
    IventoryLedgerComponent,
    LedgerTransactionsComponent,
    OrdersListingComponent,
    TimeTableByStudentComponent,
   // ReconciledDeliveriesByStockComponent,
    RequisitionsAwaitingApprovalComponent,
    RequisitionsListingComponent,
    UnreconciledDeliveriesComponent,
    InventoryComponent,
    SalesJournalComponent,
    SalesByItemComponent,
    SalesByCustomerComponent,
    AdvancedSortableDirective,
    VatComponent,
    FarmersComponent,
    /////////////////////////////////Bank////////////////////////////////////
    BankFarmersComponent,
    BankContractsByCompanyComponent,
    BankContractsByFarmerComponent,
////////////////////////////////////Buyer//////////////////////////////////////////////
    BuyerFarmersComponent,
    BuyerFarmersByCompanyComponent,
    ///////////////////////////////////Contractor/////////////////////////////////////////////////
ContractorDealsComponent,
ContractorContractsByCompanyComponent,
ContractorDealsByFarmerComponent,
ContractorDealsByTypeComponent,
ContractorExtensionOfficersByCompanyComponent,
ContractorLoanFacilitiesByFarmerComponent,
ContractorLoanFacilitiesComponent,
ContractorSalesFloorChargesComponent,
ContractorSalesSheetsByFarmerComponent,
ContractorSalesSheetsComponent,

///////////////////////////////////////////Supplier////////////////////////////////////
    SupplierFarmersComponent,
///////////////////////////////////////////Insurance///////////////////////////////////////////////
InsuranceFarmVisitsByFarmerComponent,
InsuranceFarmVisitsComponent,
//////////////////////////////////////////Timb///////////////////////////////////////////////////
TimbFarmersComponent,
//////////////////////////////////////////Transporter////////////////////////////////
TransporterFarmersComponent,
//////////////////////////////////////////Extension Officer///////////////////////////
ExtoFarmersByExtensionOfficerComponent,
//////////////////////////////////////////////////////////////////////////////////////
ExtensionOfficersComponent,
    FarmersComponent,
 //  ExtensionOfficerDetailsComponent,
    FarmsComponent,
    FarmersByCompanyComponent,
    FarmersByExtensionOfficerComponent,
    FarmersByTypeComponent,
    FarmVisitsComponent,
    FarmVisitsByExtensionOfficerComponent,
    FarmVisitsByFarmerComponent,
    FarmVisitSchedulesComponent,
    FarmVisitSchedulesByExtensionOfficerComponent,
    FarmVisitSchedulesByFarmerComponent,
    VatClaimComponent,
    MaterialCostByProductComponent,
    LoanFacilitiesComponent,
    LoanFacilitiesByFarmerComponent,
  DealsComponent ,
  DealsByTypeComponent,
  DealsByFarmerComponent,
  DealsByTypeComponent,
  DealsByFarmerComponent,
  ReportContractsComponent,
  ReportContractsByFarmerComponent,
  ReportContractsByCompanyComponent,
  ReportCompaniesComponent,
  ReportCompaniesByTypeComponent,
  ExtensionOfficersByCompanyComponent,
  ReportAfforestationLevyCreditorCollectionsComponent,
    MinistryOfAgricultureCreditorCollectionsComponent,
    PurchaseInvoicesFloorsComponent,
    RbzDailySoldBalesComponent,
   ///////////////////////////////////////////////////////////////////GVT/////////////////////////////////////
    GvtAfforestationLevyCreditorCollectionsComponent,
    GvtMinistryOfAgricultureCreditorCollectionsComponent,
    //PurchaseInvoicesFloorsComponent,
    GvtRbzDailySoldBalesComponent,
    GvtZimbabweTobaccoAssociationCreditorCollectionsComponent,
    ///////////////////////////////////////////////////////////////////////////
    SalesFloorChargesComponent,
    SalesSheetsByFarmerComponent,
    ZimbabweTobaccoAssociationCreditorCollectionsComponent,
    SalesSheetsComponent,
    ////////////////////////////////////////////////////////////////////////////////////
    CohortsBySchoolComponent,
    EmployeesBySchoolComponent,
   // PayslipsByEmployeeComponent,
   // PayslipsByPayrollComponent,
  //  PayslipByPayrollBySupplierComponent,
   // AllPayslipsByPayrollComponent,
    //OutstandingInvoicesByCohortComponent,
  //OutstandingInvoicesByStreamComponent,
  //OutstandingInvoicesByStudentComponent,
  //SalaryScheduleByPayrollComponent,
  StudentsByTheCohortComponent,
    StudentAttendancesByStudentComponent,
    StudentAttendancesByClassByDateComponent,
    StudentReportsByStudentComponent,
    StudentsByCohortComponent,
    StudentAttendancesByStudentByTermComponent,    
    StudentReportByStudentByTermComponent,
   
  PassRateByCohortComponent,
  PassRateByCohortsComponent,
  PassRateByStreamComponent,
  PassRateByStreamsComponent,
  PassRateBySubjectComponent,
  PassRateBySubjectsComponent,
  PassRateBySubjectsByStreamsComponent,
  TimeTableByClassComponent,
  TimeTablesByClassesComponent,
  TimeTablesByStudentsComponent,
  TimeTableByTeacherComponent,
  TimeTablesByTeachersComponent,
  ////////////////////////Student Reports///////////////////////////////////////
  OutstandingInvoicesByOneStudentComponent,
  StudentAttendancesByOneStudentComponent,
  StudentAttendancesByOneStudentByTermComponent,
  StudentReportByOneStudentByTermComponent,
  StudentReportsByOneStudentComponent,
  TimeTableByOneClassComponent,

  ///////////////////////Teacher Reports////////////////////////////////////////
  OutstandingInvoicesByEachStudentComponent,
  OutstandingInvoicesByOneCohortComponent,
  PassRateByOneCohortComponent,
  PassRateByOneSubjectComponent,
  PayslipsByOneEmployeeComponent,
  StudentAttendancesByEachStudentComponent,
  StudentAttendancesByOneClassByDateComponent,
  StudentReportByEachStudentByTermComponent,
  StudentReportsByEachStudentComponent,
  StudentsByOneCohortComponent,
  TimeTableByEachClassComponent,
  TimeTableByOneTeacherComponent,
  ////////////////////////Headmaster Reports///////////////////////////////////
  CohortsByTheSchoolComponent,
  EmployeesByTheSchoolComponent,
  OutstandingInvoicesByTheCohortComponent,
  PassRateByAllCohortsComponent,
  PassRateByAllStreamsComponent,
  PassRateByAllSubjectsByStreamsComponent,
  PassRateByAllSubjectsComponent,
  PayslipsByTheEmployeeComponent,
  TimeTablesByAllClassesComponent,
  TimeTablesByAllTeachersComponent,

  StudentReportsByOneStudentSakubvaComponent,
  StudentReportsByOneStudentMillenialComponent,

EmployeeAttendancesByTheSchoolByDateComponent,
  EmployeeAttendancesByEmployeeComponent,
  EmployeeAttendancesByEmployeeByTermComponent,
  StudentAttendancesByTheClassByDateComponent,
  StudentAttendancesByTheStudentComponent,
   StudentAttendancesByTheStudentByTermComponent,
 
   EmployeeAttendancesByTheSchoolByDateComponent,
   EmployeeAttendancesByEmployeeComponent,
   EmployeeAttendancesByEmployeeByTermComponent,

  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutsModule,
    AppRoutingModule,
    NgbAccordionModule,
    NgbNavModule,
    SharedModule,
    NgbTooltipModule,
    //NgxDropzoneModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgxSpinnerModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    NgbModule,
    //NgxDropzoneModule,
   // AgmCoreModule,
  ],
  bootstrap: [AppComponent],
  providers: [PageService, SortService, FilterService, GroupService, DatePipe,DbService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerHttpInterceptor, multi: true, }
  ],
  exports: [ FormsModule, ReactiveFormsModule ]
})
export class AppModule { }
